import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { store } from '@Store/configureStore';
import Router from './router';

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <Suspense fallback={<div />}>
      <Router />
    </Suspense>
  </Provider>,
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(() => {
      console.log('Service Worker registrato con successo');
    }).catch((error) => {
      console.error('Errore nella registrazione del Service Worker:', error);
    });
  });
}
