import { serviceApi } from '@Store/api';
import { ApiTags, LSKeys } from '@Src/constants';

export const configApiSlice = serviceApi.injectEndpoints({
  endpoints: (builder) => ({

    getIsManualOffline: builder.query({
      queryFn: () => ({ data: !!localStorage.getItem(LSKeys.manualOffline) }),
      providesTags: [ApiTags.manualOfflineMode],
    }),

    getIsNetworkOffline: builder.query({
      queryFn: () => ({ data: !!localStorage.getItem(LSKeys.networkOffline) }),
      providesTags: [ApiTags.networkOfflineMode],
    }),

    toggleOffline: builder.mutation({
      queryFn: () => {
        try {
          if (localStorage.getItem(LSKeys.manualOffline)) {
            localStorage.removeItem(LSKeys.manualOffline);
            return { data: undefined };
          }

          localStorage.setItem(LSKeys.manualOffline, '1');
          return { data: undefined };
        } catch (err: unknown) {
          console.error(err);

          if (err instanceof Error) {
            return {
              error: {
                status: 500,
                data: err.message,
              },
            };
          }

          return {
            error: {
              status: 500,
              data: 'Something went wrong',
            },
          };
        }
      },
      invalidatesTags: [ApiTags.manualOfflineMode],
    }),
  }),
});
