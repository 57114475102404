import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  lazy, memo, useEffect, useState,
} from 'react';
import { Paths } from '@Src/constants';
import AuthenticatedRoute from '@Components/authenticated-route';
import { useOrientation } from 'react-use';
import { isMobile } from 'react-device-detect';
import useHandleOffline from '@Src/hooks/useHandleOffline';
import { initDB } from '@Helpers/db';

const Login = lazy(() => import('@Containers/login/index'));
const Main = lazy(() => import('@Containers/main/index'));
const NotFound = lazy(() => import('@Components/not-found/index'));

function Router() {
  useHandleOffline();
  const isDBReady = useInitDB();
  const { type: orientation } = useOrientation();

  if (!isDBReady) {
    return (
      <div className="flex justify-center items-center w-screen h-screen">
        Caricamento...
      </div>
    );
  }

  if (isMobile && orientation.includes('landscape')) {
    return (
      <div className="flex justify-center items-center w-screen h-screen">
        Posiziona il dispositivo in verticale
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Helmet
        defaultTitle="FGP - Backoffice"
        titleTemplate="%s - Backoffice"
      >
        <meta content="FGP B2B" name="description" />
      </Helmet>

      <Routes>
        <Route element={<NotFound />} path="*" />

        <Route element={<Login />} path={Paths.login} />

        <Route element={<AuthenticatedRoute />}>
          <Route element={<Main />} path={Paths.home} />
        </Route>
      </Routes>

    </BrowserRouter>
  );
}

const useInitDB = () => {
  const [isDBReady, setIsDBReady] = useState<boolean>(false);

  useEffect(() => {
    initDB().then(() => {
      setIsDBReady(true);
    });
  }, []);

  return isDBReady;
};

export default memo(Router);
